//
// Logo
// --------------------------------------------------
.logo {
  margin-top: 10px;
  margin-bottom: 10px;
  z-index: 99;
  position: relative;
  height: 80px;
  & img {
  margin-left: auto;
  margin-right: auto;

  }
}
